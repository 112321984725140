.file-loader {
    margin-bottom: 45px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}
.file-loader__add {
    width: 65%;
    height: 100%;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center;
    min-height: 594px;
    max-width: 1170px;
    background-color: rgba(255, 255, 255, 0.5);
    border: 2px dashed #707070;
}
.file-loader.loading .file-loader__description,
.file-loader.loading .file-loader__image-wrap
{
    display: none;
}
.file-loader.loading .file-loader__progress-wrap {
    display: flex;
}
.file-loader__add::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.1);
    z-index: 3;
    transition: opacity .35s ease-in-out;
    opacity: 0;
    pointer-events: none;
}
.file-loader.loading .file-loader__add::after {
    opacity: 1;
    pointer-events: all;
}
.file-loader__progress-wrap {
    display: none;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 6;
}
.file-loader__image {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    padding-right: 15px;
}
.file-loader__image-width,
.file-loader__image-height,
.file-loader__image-title,
.file-loader__image-category,
.file-loader__image-type {
    width: 100%;
    margin-bottom: 15px;
}
.file-loader__image-width input,
.file-loader__image-height input,
.file-loader__image-title input,
.file-loader__image-category input,
.file-loader__image-type input {
    width: 100%;
    border: none;
    height: 35px;
    font-size: 16px;
}
.file-loader__image-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 30px;
}
.file-loader__image-wrap .file-loader__image {
    width: calc(100% - 250px);
}
.file-loader__image-wrap .file-loader__image-info {
    width: 100%;
    max-width: 250px;
    padding-left: 15px;
}
.file-loader__progress {
    width: 100%;
    height: 45px;
    border: 1px solid #000;
    max-width: 300px;
    position: relative;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.file-loader__progress-counter {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    z-index: 3;
}
.file-loader__progress-bar {
    position: absolute;
    left: 0;
    right: 100%;
    top: 0;
    bottom: 0;
    background-color: #46a546;
    transition: right .05s linear;
}
.file-loader__progress-text {
    font-size: 18px;
    font-weight: 600;
    position: relative;
    z-index: 3;
    width: 100%;
    margin-bottom: 30px;
}
#newFile.file-loader__input {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.file-loader__description {
    text-align: center;
}
.file-loader__list-wrap {
    padding-left: 30px;
    display: none;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    width: 35%;
}
.file-loader__list-wrap.active {
    display: flex;
}
.file-loader__list-title, .file-loader__list-counter {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 30px;
    width: 50%;
}
.file-loader__list-counter {
    text-align: right;
}
#avt-file-loader .file-loader__list {
    font-size: 16px;
    margin: 0;
    padding: 0;
    list-style: none;
}
#avt-file-loader .file-loader__list-item {
    margin: 0 0 15px;
    font-size: 16px;
    padding: 0;
    display: block;
    width: 100%;
    transition: all .4s ease-in-out;
    position: relative;
    padding-left: 26px;
}
#avt-file-loader .file-loader__list-item.upload.icon-upload {
    color: #378137;
}
#avt-file-loader .file-loader__list-item::before {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    visibility: visible;
    transition: color .35s ease-in-out;
}
.file-watcher * {
    box-sizing: border-box;
}
.file-watcher__btn {
    margin-bottom: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 3;
    cursor: pointer;
}
.file-watcher__popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    transition: transform .35s ease-in-out;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    z-index: 9999;
    pointer-events: none;
}
.file-watcher__content {
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.file-watcher__popup-tools {
    position: fixed;
    right: 0;
    top: 0;
    left: auto;
    bottom: 0;
    background-color: #fff;
    padding: 45px 30px 30px;
}
.file-watcher__popup img {
    display: block;
    max-width: 100%;
    height: auto;
}
.file-watcher__popup::after {
    content: "";
    position: absolute;
    top: -100%;
    bottom: -100%;
    left: -100%;
    right: -100%;
    margin: auto;
    display: block;
    opacity: 0;
    transition: opacity .35s ease-in-out;
    background-color: rgba(0,0,0,0.7);
    pointer-events: none;
}
.file-watcher__popup-image {
    padding-bottom: 65%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: calc(100% - 275px);
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.file-watcher__popup-wrap {
    max-width: 80%;
    max-height: 80%;
}
.file-watcher__popup--main .file-watcher__popup-image {
    padding-bottom: 0;
}
.file-watcher__popup--main .file-watcher__popup-image img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}
.file-watcher__input-wrap input {
    height: 35px;
}
.file-watcher__input-wrap {
    margin-bottom: 10px;
}
.file-watcher__popup-wrap {
    position: relative;
    z-index: 3;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    /* box-shadow: 0 0 99px rgba(0,0,0,0.4); */
    width: 100%;
    max-width: none;
    height: 100%;
    max-height: none;
}
.file-watcher__popup[data-open="true"] {
    transform: translateX(0);
    pointer-events: all;
}
.file-watcher__popup[data-open="true"]::after {
    opacity: 1;
    pointer-events: all;

}
.file-watcher__list {
    background-color: #fff;
    margin: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    box-sizing: border-box;
}
.file-watcher__list * {
    box-sizing: border-box;
}
.file-watcher__file {
    padding: 0 15px;
    width: 100%;
    max-width: 20%;
    margin-bottom: 30px;
    position: relative;
    cursor: pointer;
}
.file-watcher__list.--conected {
    overflow: hidden;
}
.file-watcher__list.--conected .file-watcher__file {
    width: calc(100% / 6);
}
.file-watcher__file.added:after {
    content: "";
    position: absolute;
    top: 10px;
    right: 25px;
    background-image: url("/administrator/components/com_avtimg/img/check.svg");
    width: 33px;
    height: 33px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.images-row {
    display: flex;
    margin: 0 -15px;
}
.images-row .col-explorer {
    flex: 0 0 16.66%;
}
.images-row .col-list {
    flex: 0 0 83.34%;
}
.images-row[data-explorer="true"] .col-explorer {
    flex: 0 0 16.66%;
}
.images-row[data-explorer="true"] .col-list {
    flex: 0 0 83.34%;
}
.images-row[data-explorer="false"] .col-explorer {
    flex: 0 0 0;
    width: 0;
    padding: 0;
}
.images-row[data-explorer="false"] .col-explorer:after {
    border: none;
}

.images-row[data-explorer="false"] .col-list {
    flex: 0 0 100%;
    width: 0%;
}
.images-row[data-explorer="false"] .categories-list {
    display: none;
}
.images-row[data-explorer="false"] .col-explorer__hide {
    top: -32px;
    border: 1px solid rgba(112,112,112,0.26);
}
.images-row[data-explorer="false"] .col-explorer__hide {
    font-size: 0;
    width: auto;
    left: 15px;
}
.images-row[data-explorer="false"] .col-explorer__hide .arrow {
    font-size: 13px;
    transform-origin: 50% 50%;
    transform: rotate(180deg);
}
.images-row[data-explorer="false"] .file-watcher__list .file-watcher__file {
    width: calc(100% / 6);
}
#system-debug {
    display: none;
}

#image-uploader__search {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    margin: 25px 0 60px;
}
#image-uploader__search .image-uploader__links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
}
#image-uploader__search .image-uploader__links a.active {
    background-color: #3071A9;
    color: #fff;
}
#image-uploader__search .image-uploader__links.hidden {
    display: none;
}
#image-uploader__search .image-uploader__links a.hidden {
    display: none;
}
#image-uploader__search .js-stools {
    width: auto;
    flex-grow: 2;
}
.js-stools-container-bar {
    font-size: 0;
}
.file-watcher__file {
    display: flex;
    flex-wrap: wrap;
}
.file-watcher__file * {
    pointer-events: none;
}
.file-watcher__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    width: 100%;
}
.file-watcher__file-text .name {
    font-weight: 700;
}
.file-watcher__file-name, .file-watcher__sizes {
    margin-bottom: 5px;
}
#image-uploader__search .js-stools-container-filters {
    margin-bottom: 0;
}
#image-uploader__search .js-stools-field-filter {
    margin-bottom: 0;
}
#image-uploader__search .js-stools-field-filter * {
    margin-bottom: 0;
}
.col-explorer {
    position: relative;
    padding: 0 15px;
}
.col-explorer * {
    box-sizing: border-box;
}
.col-explorer:after {
    content: "";
    background-color: #F3F3F3;
    position: absolute;
    left: 15px;
    right: 15px;
    top: 0;
    bottom: 0;
    border: 1px solid rgba(112,112,112,0.26);
}
.col-explorer__hide {
    position: absolute;
    top: -29px;
    height: 30px;
    background-color: #fff;
    border: 1px solid rgba(112,112,112,0.26);
    border-bottom: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    width: 110px;
    padding: 0 10px;
    cursor: pointer;
    z-index: 3;
    font-size: 13px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}
.col-explorer__hide * {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}
.categories-list {
    position: relative;
    z-index: 3;
    padding: 10px 10px 0;
    overflow: hidden;
}
.categories-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
}
.categories-list li {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
}
.categories-list ol {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
}
.categories-list ul li {
    margin: 0;
    padding: 0;
    list-style: none;
    color: #000;
    padding-left: 25px;
}

.categories-list ul li a {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    color: #000;
    height: 25px;
    position: relative;
}
.categories-list ul li a > span {
    position: absolute;
    left: 105%;
    white-space: nowrap;
    width: auto;
    z-index: 3;
    display: block;
}
.categories-list ul li a:after {
    content: "";
    top: 0;
    left: -10px;
    right: -10px;
    bottom: 0;
    z-index: -1;
    position: absolute;
    background-color: #000;
    opacity: 0;
}
.categories-list ul li input {
    width: 100%;
    height: 100%;
    display: block;
    margin: 0;
}
.categories-list ul li a:hover:after {
    opacity: 1;
}
.categories-list ul li a:hover > span {
    right: 0;
    left: auto;
}
.categories-list ul li a:hover:before {
    background-image: url("/administrator/components/com_avtimg/img/folder-white.svg");
}
.categories-list ul li a:hover {
    color: #fff;
}
.categories-list > ul > li {
    padding-left: 0;
}
.categories-list > ul > li > ul > li > a:after {
    left: -35px;
}
.categories-list ul li a:before {
    content: "";
    width: 17px;
    height: 15px;
    background-image: url("/administrator/components/com_avtimg/img/folder.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: inline-block;
    margin-right: 8px;
    position: relative;
    z-index: 3;
}
#adminForm {
    padding: 0 15px;
}
#adminForm * {
    box-sizing: border-box;
}
#adminForm .js-stools-container-bar * {
    box-sizing: content-box;
}
#adminForm .js-stools-container-filters * {
    box-sizing: content-box;
}


.file-watcher__popup .file-watcher__popup-input {
    height: 35px;
    border-radius: 3px;
    padding: 0 5px;
    margin: 0;
}
.file-watcher__popup .file-watcher__popup-checkbox {
    margin: 0 5px 0 0;
}
.file-watcher__input-text {
    margin-bottom: 5px;
}
.file-watcher__popup .file-watcher__checkbox-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    margin-bottom: 10px;
}
.file-watcher__add-wrap .file-watcher__popup-add {
    width: 180px;
    height: 35px;
    background-color: #37B22A;
    border-radius: 3px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 700;
}
.file-watcher__popup-close {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    &:after,
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 80%;
        transform-origin: 50% 50%;
        height: 2px;
        margin: auto;
        transform: rotate(45deg);
        background-color: #1a1b1c;
    }
    &:after {
        transform: rotate(-45deg);
    }
}
.file-watcher__popup-close img {
    max-width: 100%;
    height: auto;
    pointer-events: none;
}
.file-watcher__show-default {
    display: block;
    margin-bottom: 5px;
}
.categories-list form {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}
.categories-list input[type="submit"]{
    position: absolute;
    right: 1px;
    top: 1px;
    width: 26px;
    height: 26px;
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    background-image: url("/administrator/components/com_avtimg/img/add.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.no-photo {
    margin-left: 15px;
}

.btn-photo-lnk {
    margin: 20px 0;
}
.btn-photo-ico.icon-save-new {
    border-radius: 3px 0 0 3px;
    border-right: 1px solid #b3b3b3;
    height: auto;
    line-height: inherit;
    margin: 0 6px 0 -10px;
    opacity: 1;
    text-shadow: none;
    width: 28px;
}
.file-loader {
		margin-bottom: 45px;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		position: relative;
	}
.file-loader__add {
	width: 65%;
	height: 100%;
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-line-pack: center;
	align-content: center;
	min-height: 594px;
	max-width: 1170px;
	background-color: rgba(255, 255, 255, 0.5);
	border: 2px dashed #707070;
}
.file-loader.loading .file-loader__description,
.file-loader.loading .file-loader__image-wrap
{
	display: none;
}
.file-loader.loading .file-loader__progress-wrap {
	display: flex;
}
.file-loader__add::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(0,0,0,0.1);
	z-index: 3;
	transition: opacity .35s ease-in-out;
	opacity: 0;
	pointer-events: none;
}
.file-loader.loading .file-loader__add::after {
	opacity: 1;
	pointer-events: all;
}
.file-loader__progress-wrap {
	display: none;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 6;
}
.file-loader__image {
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
	padding-right: 15px;
}
.file-loader__image-width,
.file-loader__image-height,
.file-loader__image-title,
.file-loader__image-category,
.file-loader__image-type {
	width: 100%;
	margin-bottom: 15px;
}
.file-loader__image-width input,
.file-loader__image-height input,
.file-loader__image-title input,
.file-loader__image-category input,
.file-loader__image-type input {
	width: 100%;
	border: none;
	height: 35px;
	font-size: 16px;
}
.file-loader__image-wrap {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-bottom: 30px;
}
.file-loader__image-wrap .file-loader__image {
	width: calc(100% - 250px);
}
.file-loader__image-wrap .file-loader__image-info {
	width: 100%;
	max-width: 250px;
	padding-left: 15px;
}
.file-loader__progress {
	width: 100%;
	height: 45px;
	border: 1px solid #000;
	max-width: 300px;
	position: relative;
	justify-content: center;
	align-items: center;
	align-content: center;
}
.file-loader__progress-counter {
	display: flex;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	align-items: center;
	align-content: center;
	justify-content: center;
	font-size: 18px;
	font-weight: 500;
	z-index: 3;
}
.file-loader__progress-bar {
	position: absolute;
	left: 0;
	right: 100%;
	top: 0;
	bottom: 0;
	background-color: #46a546;
	transition: right .05s linear;
}
.file-loader__progress-text {
	font-size: 18px;
	font-weight: 600;
	position: relative;
	z-index: 3;
	width: 100%;
	margin-bottom: 30px;
}
#newFile.file-loader__input {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: block;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
}
.file-loader__description {
	text-align: center;
}
.file-loader__list-wrap {
	padding-left: 30px;
	display: none;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
	align-content: flex-start;
	width: 35%;
}
.file-loader__list-wrap.active {
	display: flex;
}
.file-loader__list-title, .file-loader__list-counter {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 30px;
	width: 50%;
}
.file-loader__list-counter {
	text-align: right;
}
#avt-file-loader .file-loader__list {
	font-size: 16px;
	margin: 0;
	padding: 0;
	list-style: none;
}
#avt-file-loader .file-loader__list-item {
	margin: 0 0 15px;
	font-size: 16px;
	padding: 0;
	display: block;
	width: 100%;
	transition: all .4s ease-in-out;
	position: relative;
	padding-left: 26px;
    height: auto;
}
#avt-file-loader .file-loader__list-item.upload.icon-upload {
	color: #378137;
}
#avt-file-loader .file-loader__list-item.upload.icon-cancel::before {
	color: #942a25;
}
#avt-file-loader .file-loader__list-item::before {
	position: absolute;
	left: 0;
	top: 0;
	display: block;
	visibility: visible;
	transition: color .35s ease-in-out;
}
.file-watcher * {
	box-sizing: border-box;
}
.file-watcher__btn {
	margin-bottom: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 3;
	cursor: pointer;
}
.file-watcher__popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform: translateX(-100%);
	transition: transform .35s ease-in-out;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	z-index: 999;
	pointer-events: none;
}
.file-watcher__popup::after {
	content: "";
	position: absolute;
	top: -100%;
	bottom: -100%;
	left: -100%;
	right: -100%;
	margin: auto;
	display: block;
	opacity: 0;
	transition: opacity .35s ease-in-out;
	background-color: rgba(0,0,0,0.7);
	pointer-events: none;
}
.file-watcher__popup.open {
	transform: translateX(0);
	pointer-events: all;
}
.file-watcher__popup.open::after {
	opacity: 1;
	pointer-events: all;

}
.file-watcher__list {
	background-color: #fff;
	margin: auto;
	width: 90vw;
	height: 90vh;
	overflow: auto;
	position: relative;
	z-index: 9999;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	align-content: flex-start;
}
.file-watcher__file {
	text-align: center;
	padding: 15px;
	width: 100%;
	max-width: 20%;
}
.file-watcher__file p {
}
.file-loader__list-item--message {
    margin-top: 5px;
    font-weight: 700;
}




.popup-open {
    overflow: hidden;
}
.avt-image-popup {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity .2s ease-in-out;
    z-index: 99999999;
    padding: 30px;
    background-color: rgba(0,0,0,0.7);

    &[data-loading="true"] {
        .avt-image-popup__wrap {
            &:before {
                opacity: 1;
                pointer-events: all;
            }
        }
    }
    &.open {
        opacity: 1;
        pointer-events: all;
    }
    &__wrap {
        background-color: $white;
        height: 100%;
        padding: 15px;
        position: relative;
        &:before {
            content: "";
            opacity: 0;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: #ffffff;
            background-image: url("/admin/images/svg/loader.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 150px, 150px;
            pointer-events: none;
            z-index: 99;
            position: absolute;
        }
    }
    &__menu {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: flex-start;
        padding-bottom: 30px;
        &-btn {
            width: 250px;
            height: 50px;
            border: 2px solid #3071A9;
            background-color: #fff;
            color: #3071A9;
            text-transform: uppercase;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 700;
            margin-right: 15px;
            border-radius: 3px;
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
    }
    &__content {
        height: calc(100% - 80px);
        overflow: hidden;
        overflow-y: auto;
        [data-content="list"],
        [data-content="add"] {
            display: none;
        }
        &[data-content="list"] {
            [data-content="list"] {
                display: flex;
            }
        }
        &[data-content="add"] {
            [data-content="add"] {
                display: flex;
            }
        }
    }
    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        height: 30px;
        width: 30px;
        cursor: pointer;
        z-index: 9;
        border-radius: 50%;
        background-color: black;
        span {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 50%;
            height: 2px;
            margin: auto;
            transform-origin: 50% 50%;
            background-color: $white;
            display: block;
            &:first-child {
                transform: rotate(45deg);
            }
            &:last-child {
                transform: rotate(-45deg);
            }
        }
    }
}
.file-watcher {
    &__file {
        &[data-used="true"] {
            position: relative;
            pointer-events: none;
            * {
                pointer-events: none;
            }
            &:before {
                content: "\f00c";
                display: flex !important;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                align-content: center;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                position: absolute;
                font-size: 40px;
                color: #28a745;
                background-color: rgba(255,255,255,0.8);
                @extend .fa;
                @extend .fas;
            }
        }
    }
    &__file-image {
        width: 100%;
        background-position: center;
        background-size: cover;
        height: 150px;
        margin-bottom: 10px;
        img {
            display: none;
        }
    }
    &__file-text {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-size: 13px;
    }
    &__sizes, &__name  {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
    }
    &__file-name {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        text-align: left;
        .name {
            width: 100%;
            max-width: 55px;
        }
        .value {
            width: 100%;
            max-width: calc(100% - 55px);
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            white-space: nowrap;
            padding-left: 5px;
        }
    }
    &__sizes * {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        text-align: left;
        .name {
            width: 100%;
            max-width: 55px;
        }
        .value {
            width: 100%;
            max-width: calc(100% - 55px);
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            white-space: nowrap;
            padding-left: 5px;
        }
    }
}
.editor-image-btn {
    width: 280px;
    height: 60px;
    border-radius: 16px;
    background-color: $blue;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: $white;
    cursor: pointer;
}


.images-list {
    margin: 0;
    padding: 0;
    list-style: none;
    &__item {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        &:last-child {
            .images-list__item-options {
                border-bottom: none;
                padding-bottom: 0;
            }
            .border-wrap {
                border-bottom: none;
            }
        }
        .input-group.image {
            font-size: 0;
            max-width: 160px;
            width: 100%;
            img {
                display: block;
                width: auto;
                height: auto;
                max-width: 160px;
                max-height: 108px;
                margin: 0 auto;
                border-radius: 3px;
                box-shadow: 0 0 12px rgba(0,0,0,0.16);
            }
        }
        &-options {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            align-content: flex-start;
            flex-grow: 2;
            padding-bottom: 20px;
            border-bottom: 1px solid #CED4DA;
        }
        &-option {
            width: 100%;
            margin-right: 50px;
            display: flex;
            align-items: center;
            align-content: center;
            &:last-child {
                margin-right: 0;
            }
            &--image {
                max-width: 160px;
            }
            &.images-list__item-option--alt {
                max-width: 305px;
                textarea, input {
                    max-width: 270px;
                    margin-left: 15px;
                    height: 93px;
                }
            }
            &--lg {
                max-width: 330px;
                flex-wrap: wrap;
                textarea, input {
                    max-width: 270px;
                    margin-left: 15px;
                }
            }
            &--size {
                max-width: 230px;
                textarea, input {
                    max-width: 90px;
                }
            }
            &--top {
                margin-bottom: 15px;
            }
            &--top,
            &--bottom {
                display: flex;
                align-items: center;
                align-content: center;
                width: 100%;
                justify-content: flex-end;
                label {
                    flex-grow: 0;
                }
                textarea, input {
                    flex-grow: 2;
                }
            }
            &--popup {
                .form-check {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    label {
                        order: 1;
                        margin-right: 15px;
                        &:after {
                            content: "";
                            width: 38px;
                            height: 38px;
                            background-color: #EFF1F3;
                            display: block;
                            position: absolute;
                            top: 0;
                            right: 0;
                            border-radius: 3px;
                            pointer-events: none;
                        }
                        &:before {
                            @extend .fa;
                            @extend .fas;
                            content: "\f00c";
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 38px;
                            height: 38px;
                            display: inline-flex !important;
                            justify-content: center;
                            align-items: center;
                            align-content: center;
                            pointer-events: none;
                            opacity: 0;
                            transition: opacity .1s ease-in-out;
                            z-index: 3;
                        }
                    }
                    .form-check-input {
                        order: 2;
                        position: relative;
                        height: 38px;
                        width: 38px;
                        margin: 0;
                        padding: 0;
                        opacity: 0;
                        border-radius: 3px;
                        cursor: pointer;
                        &:checked ~ label:before {
                            opacity: 1;
                        }
                    }
                }
            }
            label {
                font-size: 15px;
                font-weight: 400 !important;
                flex-grow: 0;
                margin: 0;
            }
            textarea, input {
                flex-grow: 2;
                height: 38px;
                resize: none;
                border-radius: 3px;
                border: none;
                background-color: #EFF1F3;
                font-size: 14px;
            }
        }
        .drag-wrapper {
            width: 50px;
            height: 50px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            border: 1px solid #ced4da;
            border-radius: 2px;
            align-self: center;
            margin-right: 20px;
            cursor: pointer;
            * {
                pointer-events: none;
            }
        }
        [id^='content_images_'] {
            .form-group {
                display: flex;
                align-items: center;
                align-content: center;
                margin: 0 10px 10px;
                width: auto;
                label {
                    flex-grow: 0;
                    font-size: 15px;
                    font-weight: 400;
                    width: auto;
                    margin-right: 15px;
                }
                textarea, input {
                    flex-grow: 2;
                    height: 38px;
                    margin: 0;
                    resize: none;
                }
                textarea {
                }
                &:nth-child(3) {
                    textarea {
                        height: 93px;
                    }
                }
            }
        }
        .input-group.current-content {
            display: none;
            resize: none;
        }
        .remove-image-element {
            width: 50px;
            height: 50px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            border-radius: 3px;
            align-self: center;
            background-color: #DC3545;
            color: #ffffff;
            cursor: pointer;
            pointer-events: all;
            * {
                pointer-events: none;
            }
        }
        .border-wrap {
            border-bottom: 1px solid #CED4DA;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            justify-content: center;
            pointer-events: none;
        }
    }
}
#images-lead-list {
    .images-list__item-option--size {
        display: none !important;
    }
}
